export const NOTIFICATIONS_URL = 'notifications';
export const STUDY_GROUPS_URL = 'study-groups';
export const ONTOLOGIES_LIST_URL = 'ontologies/sh1';

export const PREBUILT_EXAMS_URL = 'prebuilt-exams';
export const PREBUILT_EXAMS_URL_CARD_URL = `${PREBUILT_EXAMS_URL}/cards/list`;

export const PREBUILT_EXAMS_URL_LIST_URL = `${PREBUILT_EXAMS_URL}/exams/list`;

export const PREBUILT_RANDOM_EXAM_URL = (
  examCode: string,
  subjectCode: string,
  year?: number | 'all'
) =>
  `prebuilt-exams/exams/${examCode}/${subjectCode}/random${
    year && year !== 'all' ? `?year=${year}` : ''
  }`;
export const PREBUILD_ADAPTIVE_EXAM_URL = 'practice/tests/adaptive';
export const PRACTICE_TEST_URL = 'practice/test';

export const PREBUILT_SCHOOL_EXAM_URL = (
  urn: string,
  subjectCode: string,
  subjectTypeCode: string
) =>
  `schools/${(urn || '')?.replace(
    'urn:',
    ''
  )}/exams/${subjectCode}/${subjectTypeCode}`;

export const PREBUILT_CONVERT_TO_TEACHER_EXAM_URL = (examId: string) =>
  `${PREBUILT_EXAMS_URL}/${examId}/convert/teacher-exam?events=content`;

export const USERS_URL = 'users';
export const READING_PASSAGE_URL = 'events/passages';

export const REGENERATE_CODE_URL = 'regenerate-code';
export const TEACHERS_URL = 'teachers';
export const INTERESTS_URL = 'interests';
export const RESOURCES_URL = 'resources';
export const RESEARCH_ITEMS_URL = 'research-items';
export const DASHBOARD_URL = 'dashboard';
export const FEEDBACK_URL = `feedback`;
export const GAME_SESSIONS_URL = 'game-sessions';
export const SESSION_URL = 'session';
export const SESSIONS_URL = 'sessions';
export const START_URL = 'start';
export const PAUSE_URL = 'pause';
export const UNPAUSE_URL = 'unpause';
export const COMPLETE_URL = 'complete';
export const NEW_URL = 'new';
export const TEACHER_EXAM_URL = 'teacher-exams';
export const DETAILS_URL = 'details';
export const HOMEWORK_TESTS_URL = 'homework-tests';
export const HOMEWORK_TEST_URL = 'homework-test';
export const INVITATION_URL = 'invitations';
export const FLASHCARDS = 'flashcards';
export const ASSESSMENT_URL = 'assessment';
export const ASSESSMENT_REVIEW_URL = 'assessment-review';
export const PLOT_DATA_URL = 'plot-data';
export const SUBJECTS_BY_STUDY_GROUP = 'subjects-by-study-group';
export const STUDENT_URL = 'student';
export const STUDENT_PORTAL_URL = 'student-portal';
export const STUDENTS_URL = 'students';
export const ACCURACY_URL = 'accuracy';
export const EVENTS_URL = 'events';
export const EVENT_URL = 'event';
export const STAFF_URL = 'staff';
export const SCHOOLS_URL = 'schools';
export const DIAGNOSTICS_URL = 'diagnostics';
export const PROGRESS_URL = 'progress';
export const PAST_PAPERS_URL = 'past-papers';
export const SUBJECTS_BY_TEACHER_URL = 'subjects-by-teacher';
export const TEACHER_URL = 'teacher';
export const ASSISTANTS_URL = 'assistants';
export const WEEKS_URL = 'week';
export const ACTIVE_SESSIONS_URL = 'active-sessions';
export const YEAR_AVERAGE_URL = 'year-average';
export const INFORMATION_URL = 'information';
export const GEOGEBRA_URL = 'geogebra';
export const PERFOMANCE_URL = 'performance';
export const EXAM_URL = 'exam';
export const SUBJECT_URL = 'subject';
export const TOPIC_URL = 'topic';
export const MISTAKES_URL = 'mistakes';

export const DIAGNOSTICS_PROGRESS_URL = `${DIAGNOSTICS_URL}/${PROGRESS_URL}`;
export const DIAGNOSTICS_STUDENT_PORTAL_URL = `${DIAGNOSTICS_URL}/${STUDENT_PORTAL_URL}`;
export const DIAGNOSTICS_STUDENT_PORTAL_PROGRESS_URL = `${DIAGNOSTICS_STUDENT_PORTAL_URL}/${PROGRESS_URL}`;
export const DIAGNOSTICS_PAST_PAPERS_URL = `${DIAGNOSTICS_URL}/${PAST_PAPERS_URL}`;
export const DIAGNOSTICS_PAST_PAPERS_YEAR_AVERAGE_URL = `${DIAGNOSTICS_PAST_PAPERS_URL}/${YEAR_AVERAGE_URL}`;
export const DIAGNOSTICS_PAST_PAPERS_STUDENTS_URL = `${DIAGNOSTICS_URL}/${PAST_PAPERS_URL}/${STUDENTS_URL}`;
export const DIAGNOSTICS_STUDENT_URL = `${DIAGNOSTICS_URL}/${STUDENT_URL}`;
export const DIAGNOSTICS_ACCURACY_URL = `${DIAGNOSTICS_URL}/${ACCURACY_URL}`;
export const DIAGNOSTICS_ACCURACY_STUDENTS_URL = `${DIAGNOSTICS_URL}/${ACCURACY_URL}/${STUDENTS_URL}`;
export const DIAGNOSTICS_ACCURACY_STUDENT_URL = `${DIAGNOSTICS_URL}/${ACCURACY_URL}/${STUDENT_URL}`;
export const TEACHERS_WEEKS_URL = `${TEACHERS_URL}/${WEEKS_URL}`;
export const TEACHERS_STUDENT_URL = `${TEACHERS_URL}/${STUDENT_URL}`;
export const STUDY_GROUPS_STUDENTS_URL = `${STUDY_GROUPS_URL}/${STUDENTS_URL}`;
export const DIAGNOSTICS_ACCURACY_YEAR_AVERAGE_URL = `${DIAGNOSTICS_ACCURACY_URL}/${YEAR_AVERAGE_URL}`;

export const GEOGEBRA_GRADE_HAS_GEOGEBRA_URL = `${GEOGEBRA_URL}/grade-has-geogebra`;
export const GEOGEBRA_AVAILABLE_SUBJECTS_URL = `${GEOGEBRA_URL}/available-subjects`;
export const GEOGEBRA_ALL_TOPICS_WITH_ONE_GAME = `${GEOGEBRA_URL}/all-topics-with-one-random-game`;
export const GEOGEBRA_SESSION_URL = `${GEOGEBRA_URL}/${SESSION_URL}`;

export const STUDENTS_BOOKS_URL = `students/books`;

export const WONDE_SCHOOLS = 'wonde/schools';

export const TEACHERS_ASSISTANTS_URL = `${TEACHERS_URL}/${ASSISTANTS_URL}`;

export const EXAMS_URL = 'exams';
export const EXAMS_SUBJECTS_URL = (code: string) =>
  `${EXAMS_URL}/${code}/subjects`;

export const LIBRARY_URL = 'library';
export const ASSIGNMENTS_URL = 'assignments';
export const TEACHER_EXAMS_URL = 'teacher-exams';
export const TEACHER_EXAMS_SUBJECTS_BY_STUDY_GROUP_URL = `${TEACHER_EXAMS_URL}/${SUBJECTS_BY_STUDY_GROUP}`;
export const TEACHER_EXAMS_SUBJECTS_BY_TEACHER_URL = `${TEACHER_EXAMS_URL}/${SUBJECTS_BY_TEACHER_URL}`;
export const TEACHER_SINGLE_EXAM_URL = 'teacher-exam';
export const TEST_URL = 'test';
export const TEACHERS_STUDY_GROUPS_URL = `${TEACHERS_URL}/study-groups`;
export const TEACHERS_STUDY_GROUPS_BY_BOOK = `${TEACHERS_URL}/study-groups-by-book`;
export const TEACHER_EXAM_DRAFTS_URL = `${TEACHER_EXAMS_URL}/drafts`;
export const TEACHER_EXAM_UNASSIGNED_URL = `${TEACHER_EXAMS_URL}/${LIBRARY_URL}`;
export const TEACHER_EXAM_SCHOOL_LIBRARY_URL = `${TEACHER_EXAMS_URL}/school-library`;
export const TEACHER_EXAM_ASSIGNED_URL = `${TEACHER_EXAMS_URL}/${ASSIGNMENTS_URL}`;
export const DIAGNOSTICS_TEACHER_EXAMS_URL = `${DIAGNOSTICS_URL}/${TEACHER_EXAMS_URL}`;
export const DIAGNOSTICS_PLOT_DATA_URL = `${DIAGNOSTICS_URL}/${PLOT_DATA_URL}`;
export const DIAGNOSTICS_TEACHER_EXAM_URL = `${DIAGNOSTICS_URL}/${TEACHER_SINGLE_EXAM_URL}`;
export const TEACHER_ASSIGNMENTS_URL = `${TEACHER_EXAMS_URL}/${LIBRARY_URL}/${ASSIGNMENTS_URL}`;

export const TEACHER_EXAMS_INFORMATION_URL = `${TEACHER_EXAMS_URL}/${INFORMATION_URL}`;

export const SYLLABUS_URL = 'syllabus';

export const EVENTS_EVENT_URL = `${EVENTS_URL}/${EVENT_URL}`;

export const EVENTS_CONTENT_URL = `${EVENTS_URL}/content`;

export const PROFILE_URL = 'profile';
export const PROFILE_BLOCKED_URL = `${PROFILE_URL}/blocked`;
export const PROFILE_HIDDEN_URL = `${PROFILE_URL}/hidden`;
export const UNBLOCK_PROFILE_URL = (userId: string) =>
  `${PROFILE_URL}/${userId}/unblock`;
export const UNHIDE_PROFILE_URL = (userId: string) =>
  `${PROFILE_URL}/${userId}/unhide`;
export const STUDENT_PROFILE = `${PROFILE_URL}/student`;
export const TEACHER_PROFILE_URL = `${PROFILE_URL}/teacher`;
export const DELETE_PENDING_STUDENTS_URL = `${PROFILE_URL}/delete-pending-students`;
export const TEACHER_STUDENTS_PROFILE_URL = `${TEACHER_PROFILE_URL}/students`;
export const TEACHER_PENDING_STUDENTS_PROFILE_URL = `${TEACHER_PROFILE_URL}/pending-students`;
export const PROFILE_NO_OF_STUDENTS_URL = `${PROFILE_URL}/no-of-students`;

export const INTEREST_TOPICS_URL = `${INTERESTS_URL}/topics`;
export const RESEARCH_ITEMS_TAGS_URL = `${RESEARCH_ITEMS_URL}/tags`;

export const DASHBOARD_TEACHER_URL = `${DASHBOARD_URL}/teacher`;
export const DASHBOARD_ALL_URL = `${DASHBOARD_URL}/all`;
export const DASHBOARD_TEACHER_HOMEWORK_URL = `${DASHBOARD_TEACHER_URL}/homework`;
export const RESOURCES_CATEGORIES_URL = `${RESOURCES_URL}/categories`;
export const RESOURCES_SUBJECTS_URL = `${RESOURCES_URL}/subjects`;

export const DASHBOARD_TEACHER_ASSIGNMENTS_URL = `${DASHBOARD_TEACHER_URL}/${ASSIGNMENTS_URL}`;
export const DASHBOARD_TEACHER_ASSIGNMENTS_CLASSWORK_URL = `${DASHBOARD_TEACHER_ASSIGNMENTS_URL}/classwork`;
export const DASHBOARD_TEACHER_ASSIGNMENTS_EXAM_URL = `${DASHBOARD_TEACHER_ASSIGNMENTS_URL}/exam`;
export const DASHBOARD_TEACHER_ASSIGNMENTS_HOMEWORK_URL = `${DASHBOARD_TEACHER_ASSIGNMENTS_URL}/homework`;
export const GAME_SESSIONS_TEACHER_URL = `${GAME_SESSIONS_URL}/teacher`;
export const GAME_SESSIONS_TEACHER_ACTIVE_SESSIONS_URL = `${GAME_SESSIONS_TEACHER_URL}/active-sessions`;
export const STUDENTS_ACTIVE_SESSIONS_URL = `${STUDENTS_URL}/${ACTIVE_SESSIONS_URL}`;

export const LEARNER_EXAM_URL = `${DASHBOARD_URL}/todo`;

export const DEEP_WRITER_ANSWER_URL = (sessionId: string, eventId: string) =>
  `${SESSIONS_URL}/${sessionId}/answer/${eventId}`;

export const DEEP_WRITER_SCORE_CHANGE_URL = (
  sessionId: string,
  type: string,
  action: string
) => `${SESSIONS_URL}/score/${sessionId}/${type}/${action}`;

export const EVENT_PASSAGES_SUMMARY_URL = (id: string) =>
  `${READING_PASSAGE_URL}/${id}/summary`;

export const DASHBOARD_LEARNER_DIAGNOSTIC_URL = `${DASHBOARD_URL}/diagnostics`;
export const DASHBOARD_LEARNER_CHALLENGES_URL = `${DASHBOARD_URL}/challenges`;
export const DASHBOARD_PARENT_DIAGNOSTIC_URL = `${DASHBOARD_URL}/learner`;
export const DASHBOARD_LEARNER_SUBJECT_COMPARISON_URL = `${DASHBOARD_URL}/diagnostics/subject-comparison`;

export const HOMEWORK_TEST_SESSIONS_CREATE_URL = `${HOMEWORK_TEST_URL}/${SESSIONS_URL}/create`;

export const DASHBOARD_STUDENT_HOMEWORK_URL = `${DASHBOARD_URL}/homework`;
export const DASHBOARD_STUDENT_CLASSWORK_URL = `${DASHBOARD_URL}/classwork`;
export const DASHBOARD_STUDENT_EXAM_URL = `${DASHBOARD_URL}/exam`;
export const DASHBOARD_FLASHCARDS_PREPSHEETS_URL = `${DASHBOARD_URL}/flashcards-prepsheets`;

export const FRIENDS_URL = 'friends';
export const FRIENDS_STATUS_URL = `${FRIENDS_URL}/status`;

export const SUBSCRIPTIONS_WITH_LEARNERS = `${PROFILE_URL}/subscriptions-with-learners`;

export const USERS_COMMON_PREFERENCES_URL = `${USERS_URL}/comm-preferences`;

export const SCHOOL_GENRES_URL = 'events/passages/genres/all';

export const SCHOOL_YEARS_URL = 'schools/years';
export const SCHOOL_YEARS_BY_SUBJECT_STRAND_URL = (
  code: string,
  subject: string,
  strand: string
) => `ontologies/${code}/topics/${subject}/section/${strand}/count`;
export const SCHOOL_YEARS_BY_SUBJECT = (code: string, subject: string) =>
  `ontologies/${code}/topics/${subject}/count`;

export const SCHOOL_DETAILS_URL = (id: string) => `schools/${id}/details`;

export const FLASHCARDS_DECKS_URL = `${FLASHCARDS}/decks`;
export const FLASHCARDS_ADD_DECK_TO_LIBRARY_URL = `${FLASHCARDS}/add-deck-to-library`;
export const FLASHCARDS_GLOBAL_DECKS_URL = `${FLASHCARDS}/${LIBRARY_URL}`;
export const FLASHCARDS_BY_CATEGORY = `${FLASHCARDS}/category-by-topic`;
export const FLASHCARDS_CARDS_URL = `${FLASHCARDS}/cards`;
export const FLASHCARDS_DECKS_SELECTED_URL = `${FLASHCARDS_DECKS_URL}/selected`;

export const TEACHER_EXAMS_REGENERATE_EVENT = (eventId: string) =>
  `${TEACHER_EXAM_URL}/events/${eventId}/regenerate`;

export const TEACHER_EXAMS_GENERATE_MULTIPLE_SKILLS = `${TEACHER_EXAM_URL}/events/generate/multiple-skills`;

export const ONTOLOGY_TOPIC_URL = (
  code: string,
  years: number[],
  subject?: string,
  section?: string
) =>
  `ontologies/${code}/topics?years=${years?.toString()}&subject=${subject}&section=${section}`;

export const FORGOT_PASSWORD_URL = 'users/forgot-password';
export const AUTH_VERIFY_URL = 'auth/verify-code';

export const STAFF_TEACHERS_URL = `${STAFF_URL}/teachers`;
export const STAFF_TEACHER_URL = (teacherId: string) =>
  `${STAFF_URL}/teachers/${teacherId}`;
export const STAFF_TEACHERS_INVITE_URL = (teacherId: string) =>
  `${STAFF_URL}/teachers/${teacherId}/send-invitation`;

export const SCHOOLS_CLASSES_URL = `${SCHOOLS_URL}/classes`;
export const SCHOOLS_STUDENTS_URL = `${SCHOOLS_URL}/students`;
export const SCHOOLS_CLASSES_SUBJECTS_URL = `${SCHOOLS_CLASSES_URL}/subjects`;
export const SCHOOLS_CLASSES_YEARS_URL = `${SCHOOLS_CLASSES_URL}/years`;
export const STAFF_TEACHER_CURRENT_URL = `${STAFF_URL}/${TEACHER_URL}/current`;

export const MODULES = 'modules';
export const QUIZZES = 'quizzes';

export const MODULES_EXAM_COMPLETED = (examCode: string) =>
  `${MODULES}/exam/${examCode}/completed`;

export const MODULES_REVIEW = (groupId: string) =>
  `${MODULES}/${groupId}/review`;

// QUIZZES
export const DEEP_READER_TAKE_QUIZ_TEST_URL = `${QUIZZES}/take-quiz-test`;

// Deep Reader
export const DEEP_READER_URL = 'deep-reader';
export const DEEP_READER_BK_URL = `${DEEP_READER_URL}/background-knowledge/get-section`;
export const DEEP_READER_HUW_URL = `${DEEP_READER_URL}/huw/get-section`;
export const DEEP_READER_BK_HUW_URL = `${DEEP_READER_URL}/bk-huw/get-section`;
export const DEEP_READER_COMMENTS_URL = `${DEEP_READER_URL}/comments`;
export const DEEP_READER_THREADS_URL = `${DEEP_READER_URL}/threads`;
export const DEEP_READER_RESOURCES_URL = `${DEEP_READER_URL}/resources`;
export const DEEP_READER_ANNOTATIONS_URL = `${DEEP_READER_URL}/annotations`;
export const DEEP_READER_LIBRARY_URL = `${DEEP_READER_URL}/${LIBRARY_URL}`;
export const DEEP_READER_FLASHCARDS_URL = `${DEEP_READER_URL}/flashcards`;
export const DEEP_READER_QUIZZES_GENERATION_URL = `${DEEP_READER_URL}/${QUIZZES}/generate`;
export const DEEP_READER_QUIZZES_TOPICS_AND_QUESTIONS_TYPES_URL = ({
  isbn,
  section,
}: {
  isbn: number;
  section: number;
}) =>
  `${DEEP_READER_URL}/quizzes/topic-list-and-questions-quantity/${isbn}/${section}`;

type TAssignmentParams = {
  isbn: number;
  groupId: string;
  section: number;
};

export const DEEP_READER_READING_ASSIGNMENT_URL = ({
  groupId,
  isbn,
  section,
}: TAssignmentParams) =>
  `${DEEP_READER_URL}/assignments/assignments-by-group/${groupId}/${isbn}/${section}`;

export const DEEP_READER_READING_ASSIGNMENTS_BY_STUDENT_URL = `${DEEP_READER_URL}/assignments/assignments-by-student`;
export const DEEP_READER_READING_ASSIGNMENT_BY_STUDENT_URL = `${DEEP_READER_URL}/assignments/assignment-by-student`;

export const DEEP_READER_ASSIGN_TO_GROUP_URL = `${DEEP_READER_URL}/assignments/assign-to-group`;

export const DEEP_READER_CHANGE_ASSIGNMENT_GROUP_URL = (
  isbn: number,
  section: number,
  oldGroupId: string
) =>
  `${DEEP_READER_URL}/assignments/change-assignment-group/${oldGroupId}/${isbn}/${section}`;

export const DEEP_READER_UNASSIGN_CHAPTER_URL = ({
  groupId,
  isbn,
  section,
}: TAssignmentParams) =>
  `${DEEP_READER_URL}/assignments/unassign-to-group/${groupId}/${isbn}/${section}`;

export const DEEP_READER_UPDATE_ASSIGNMENT_DATES_URL = ({
  groupId,
  isbn,
  section,
}: TAssignmentParams) =>
  `${DEEP_READER_URL}/assignments/change-assign-dates/${groupId}/${isbn}/${section}`;

export const DEEP_READER_DECK_URL = ({
  resourceId,
  section,
  groupId,
}: {
  resourceId: string;
  section: number;
  groupId: string;
}) =>
  `flashcards/decks/deep-reader-resource/${resourceId}/${section}/${groupId}`;

export const DEEP_READER_BACKGROUND_KNOWLEDGE_URL = (
  isbn: number,
  section: number
) => `${DEEP_READER_BK_URL}/${isbn}/${section}`;

export const HUW_URL = (isbn: number, section: number) =>
  `${DEEP_READER_BK_URL}/${isbn}/${section}`;

export const BK_HUW_URL = (isbn: number, section: number) =>
  `${DEEP_READER_BK_URL}/${isbn}/${section}`;

export const RESOURCES_FAVORITES_URL = `${RESOURCES_URL}/favourites`;
export const RESOURCES_ADD_TO_FAVORITES_URL = (resourceId: string) =>
  `${RESOURCES_URL}/${resourceId}/favourite`;

export const RESEARCH_ITEMS_FAVORITES_URL = `${RESEARCH_ITEMS_URL}/favourites`;
export const RESEARCH_ITEMS_ADD_TO_FAVORITES_URL = (resourceId: string) =>
  `${RESEARCH_ITEMS_URL}/${resourceId}/favourite`;
