// fonts.ts

export const custom = {
  font: {
    xxs: '9px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '20px',
    xl: '24px',
    xxl: '32px',
    xxxl: '48px',
  },
  space: {
    xs: '2px',
    sm: '4px',
    md: '8px',
    mdl: '12px',
    lg: '16px',
    lgx: '20px',
    xl: '24px',
    xxl: '32px',
    '2xl': '48px',
    xxxl: '64px',
    xxxxl: '128px',
    '5xl': '134px',
  },
  radius: {
    xxs: '8px',
    xs: '8px',
    sm: '16px',
    md: '21px',
    lg: '24px',
    '50': '50%',
  },
  rightSidebarWidth: {
    desktop: '80px',
    mobile: '80px',
    mobilePortait: '74px',
  },
  rightSidebarDeepReadingWidth: {
    desktop: '131px',
    mobile: '113px',
    mobilePortait: '74px',
  },
  leftSidebarWidth: {
    desktop: '245px',
    mobile: '297px',
  },
  deepReadingRightPanelWidth: {
    desktop: '457px',
    tabletBig: '379px',
    mobile: '250px',
  },
  morphologySidebarWidth: {
    desktop: '459px',
    tabletBig: '379px',
    mobile: '250px',
  },
  examsReviewSidebarWidth: {
    desktop: '435px',
    tabletBig: '379px',
    mobile: '340px',
  },
  SMBSidebarWidth: {
    desktop: '623px',
    tabletBig: '430px',
    mobile: '390px',
    equal: '45%',
  },
  tableOfContents: {
    desktop: '360px',
    tabletBig: '300px',
    mobile: '250px',
  },
  examsViewSidebarWidth: {
    desktop: '459px',
    tabletBig: '379px',
    mobile: '0px',
  },
  flashcardsRightPanel: {
    desktop: '458px',
    tabletBig: '380px',
    mobile: '250px',
  },
  loginRegisterDrawerSize: {
    desktop: '616px',
    tabletBig: '430px',
    mobile: '390px',
    equal: '45%',
  },
  layoutMaxSize: {
    header: 1595,
    content: 1419,
  },
  transition03: 'all 0.3s ease-in-out',
  transition02: 'all 0.2s ease-in-out',
  wrapperWidth: {
    desktop: '1920px',
    tabletBig: '100%',
    tablet: '100%',
    mobile: '100%',
  },
};

export type CustomSidePanelVariantType =
  | 'rightSidebarWidth'
  | 'rightSidebarDeepReadingWidth'
  | 'leftSidebarWidth'
  | 'deepReadingRightPanelWidth'
  | 'morphologySidebarWidth'
  | 'examsReviewSidebarWidth'
  | 'tableOfContents';
